@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

$banner-header: 62px;

.bottom-banner {
    position: fixed;
    bottom: 0;
    left: 10px;
    z-index: 9;
    width: 200px;
    min-width: 200px;
    max-width: 80vh;
    display: none;
    text-decoration: none;
    @include transition;
    @include media(md) {
        display: block;
        transform-origin: bottom left;
        transform: translateY(66%);
        transform: translateY(calc(100% - #{$banner-header} - 35px)) scale(0.7);
    }
    @include media(lg) {
        transform: translateY(calc(100% - #{$banner-header} - 22px)) scale(0.8);
    }
    @include media(xl) {
        transform: translateY(calc(100% - #{$banner-header} - 10px)) scale(0.9);
    }
    @include media(xxl) {
        transform: translateY(calc(100% - #{$banner-header}));
    }
    &-header {
        height: $banner-header;
        background-color: $afitv-color;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        @include media(xl) {
            padding: 0 25px;
        }
        &-logo {
            flex: 0 1 65%;
            max-width: 65%;
            @include transition;
            img {
                width: auto;
                max-width: 100%;
                max-height: $banner-header - 30px;
                @include transition;
                @include media(xl) {
                    max-height: $banner-header - 20px;
                }
            }
        }
        &-content {
            flex: 1 1 auto;
            max-width: 1%;
            font-weight: 900;
            padding: 0 10px;
            font-size: 13px;
            visibility: hidden;
            transition: opacity,padding 200ms ease-in-out;
            opacity: 0;
            @include media(xl) {
                font-size: 15px;
            }
        }
        &-play {
            flex: 0 1 35px;
            max-width: 35px;
            @include media(xl) {
                flex: 0 1 40px;
                max-width: 40px;
            }
            img {
                height: 35px;
                @include media(xl) {
                    height: 40px;
                }
            }
        }
    }
    &-image {
        img {
            width: 100% !important;
        }
    }
    &:hover,
    &:focus {
        width: 400px;
        transform: translateY(0%) scale(0.7);
        @include media(lg) {
            transform: translateY(0%) scale(0.8);
        }
        @include media(xl) {
            transform: translateY(0%) scale(0.9);
        }
        @include media(xxl) {
            transform: translateY(0%);
        }
        .bottom-banner-header {
            height: 15px + $banner-header;
        }
        .bottom-banner-header-logo {
            flex: 0 1 33.3333% !important;
            max-width: 33.3333% !important;
        }
        .bottom-banner-header-content {
            max-width: 70%;
            visibility: visible;
            opacity: 1;
            animation-delay: 300ms;
            padding: 0 15px;
        }
    }
}
