$path-font: "../fonts/tabac";

$color-black: #000;
$color-white: #fff;
$color-grey:  #999;

$color-primary: #ffd100;
$color-secondary: #999999;
$color-secondary-light: #e0e0e0;

$color-bg: #000000;
$color-hover: #797777;
$color-red: #ff5445;
$color-blue: #365fd6;
$color-green: #30a24e;
$afitv-color: #ff0e2e;

$colorv2-gray: #ebebeb;
$colorv2-red: #f63c37;
$colorv2-gray-2: #e1e1e1;
$colorv2-gray-dark: #666666;

$color-base: #000;
$color-link: $color-primary;
$color-link-hover: $color-primary;

$color-border: #e0e0e0;
$color-inactive: #f0f0f0;

$font-size: 16px;

$font-base: Arial, Helvetica, sans-serif;

$transition-function:			ease;
$transition-duration: 			250ms;

$btn-bg:						$color-white;
$btn-border: 				$color-black;
$btn-color: 				$color-black;

$btn-bg-hover: 			darken($btn-bg,15%);
$btn-border-hover: 	darken($btn-border,10%);

$btn-height: 				40px;
$btn-border-width: 	1px;

$input-light: #f2f2f2;
$input-dark: #c7c7c7;
$input-darken: #7a7a7a;

//arrays
$share-icon-names: (
  google: "\e915",
  tumblr: "\e916",
  facebook: "\e914",
  mail: "\e911",
  twitter: "\e912",
  linkedin: "\e910",
  vine: "\e913",
  youtube: "\e906"
);
